<template>
<div>
  <div ref="confirm-code" class="login col-6 m-auto mt-5">
    <p for="">{{ data.email }} {{ $t('resetPassword.send_email') }}<br>
        {{ $t('resetPassword.require') }}</p>
    <div class="form-group">
        <label for="">{{ $t('resetPassword.code') }}</label>
      <Input id="code"
               :placeholder="$t('resetPassword.code')"
               :error-messages="errors.code"
               type="text"
               :value="data.code"
               @keyup="onHandleChange('code', data.code)"
               @onHandleChangeValue="(value) => onHandleChange('code', value)"/>
    </div>
    <a @click="resendMail()"  class="text-primary">{{ $t('resetPassword.resend') }}</a>
    <br>
    <Button @click="confirm" class-name="btn btn-primary">
        {{ $t('resetPassword.sendCode') }}
      </Button>
  </div>
  <div ref="change-password" class="login col-6 m-auto mt-5 d-none">
    <div class="form-group">
        <label for="">{{ $t('resetPassword.password') }}</label>
      <Input id="password"
               :placeholder="$t('resetPassword.password')"
               :error-messages="errorsResetPass.password"
               :type="'password'"
               :value="dataChangePass.password"
               @keyup="onHandleChangeSubmitResetPass('password', dataChangePass.password)"
               @onHandleChangeValue="(value) => onHandleChangeSubmitResetPass('password', value)"/>
    </div>
    <div class="form-group">
        <label for="">{{ $t('resetPassword.password_confirm') }}</label>
      <Input id="password_confirm"
               :placeholder="$t('resetPassword.password_confirm')"
               :error-messages="errorsResetPass.password_confirm"
               :type="'password'"
               :value="dataChangePass.password_confirm"
               @keyup="onHandleChangeSubmitResetPass('password_confirm', dataChangePass.password_confirm)"
               @onHandleChangeValue="(value) => onHandleChangeSubmitResetPass('password_confirm', value)"/>
    </div>
    <br>
    <Button @click="changePassword" class-name="btn btn-primary">
        {{ $t('resetPassword.resetPass') }}
      </Button>
  </div>
</div>
</template>

<script>
import AuthRepository from "@/services/api/AuthRepository";
import { SUCCESS } from "../../constants";
import Input from "../../components/common/Input";
import { confirmCodeFormSchema, submitResetPassFormSchema } from "../../common/schemaValidation";
import Button from "../../components/common/Button";
import { mapMutations } from "vuex";

export default {
    name: "Login",
    components: {
        Input,
        Button
    },
    data() {
        return {
            data: {
                email: this.$route.params.email,
                code: null,
            },
            dataChangePass: {
                user_id: null,
                password: null,
                password_confirm: null
            },
            errors: {
                code: "",
            },
            errorsResetPass: {
                password: null,
                password_confirm: null
            },
        };
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        resendMail() {
            this.setIsLoading(true);
            AuthRepository.sendMail(this.data)
                .then((res) => {
                    this.setIsLoading(false);
                    if (res.status === SUCCESS) {
                        this.$router.push({ name: "resetPasswordConfirm", params: { email: res.data.data } });
                    }
                })
        },
        confirm() {
            confirmCodeFormSchema
                .validate(this.data, { abortEarly: false })
                .then(() => {
                    this.errors = {};
                    this.setIsLoading(true);
                    AuthRepository.ConfirmCode(this.data)
                        .then((res) => {
                            this.setIsLoading(false);
                            if (res.status === SUCCESS) {
                                this.dataChangePass.user_id = res.data.data.id;
                                this.$refs["confirm-code"].classList.add("d-none");
                                this.$refs["change-password"].classList.remove("d-none");
                            }else{
                                this.$refs["confirm-code"].classList.remove("d-none");
                                this.$refs["change-password"].classList.add("d-none");
                            }
                        })
                })
                .catch(err => {
                    this.setIsLoading(false);
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        changePassword() {
            submitResetPassFormSchema
                .validate(this.dataChangePass, { abortEarly: false })
                .then(() => {
                    this.setIsLoading(true);
                    this.errorsResetPass = {};
                    AuthRepository.ChangeResetPassword(this.dataChangePass)
                        .then((res) => {
                            this.setIsLoading(false);
                            if (res.status === SUCCESS) {
                                this.$toast.success("パスワードを設定しました。", {})
                                this.$router.push({ name: "login" });
                            }
                        })
                })
                .catch(err => {
                    this.setIsLoading(false);
                    err.inner.forEach(error => {
                        this.errorsResetPass[error.path] = error.message;
                    });
                });
        },
        onHandleChange(field, value) {
            this.data[field] = value.trim()
            this.validate(field)
        },
        validate(field) {
            confirmCodeFormSchema
                .validateAt(field, this.data)
                .then(() => {
                    this.errors[field] = ""
                })
                .catch(err => {
                    this.errors[field] = err.message
                });
        },
        onHandleChangeSubmitResetPass(field, value) {
            this.dataChangePass[field] = value.trim()
            this.validateSubmit(field)
        },
        validateSubmit(field) {
            submitResetPassFormSchema
                .validateAt(field, this.dataChangePass)
                .then(() => {
                    this.errorsResetPass[field] = ""
                })
                .catch(err => {
                    this.errorsResetPass[field] = err.message
                });
        },
    },
};
</script>
