<template>
  <div class="mgt-10">
    <v-text-field :type="type"
                  :id="id"
                  :name="name"
                  :class="className"
                  v-model="input"
                  :placeholder="placeholder"
                  :rounded="rounded"
                  solo
                  :error-messages="errorMessages"
                  :disabled="disabled"
                  :value="value"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
    name: "Input",
    props: {
        id: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        className: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            validator: function (value) {
                return ["text", "email", "password", "tel", "number"].includes(value)
            },
            default: "text"
        },
        value: {
            type: [String, Number],
            default: ""
        },
        errorMessages: {
            type: String,
            default: ""
        },
        rounded: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.input = this.value
    },
    data() {
        return {
            input: ""
        }
    },
    watch: {
        input(newVal) {
            this.onHandleChange(newVal)
        }
    },
    methods: {
        onHandleChange(value) {
            this.$emit("onHandleChangeValue", value)
        }
    }
};
</script>

<style lang="sass" scoped>
  @import "../../styles/variable"
  .text-error
    color: $danger
  .mgt-10
    margin-top: 10px
</style>