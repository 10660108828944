<template>
  <v-btn
    :id="id"
    :class="className"
    :rounded="rounded"
    :color="color"
    :large="large"
    :small="small"
    :x-large="xLarge"
    :x-small="xSmall"
    @click="$emit('click')"
    :type="type"
    :disabled="disabled"
    :width="width"
    :height="height"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
    name: "Button",
    props: {
        id: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: "primary"
        },
        className: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            validator: function (value) {
                return ["submit", "reset", "button"].includes(value)
            },
            default: "button"
        },
        size: {
            type: String,
            validator: function (value) {
                return ["large", "small", "x-large", "x-small"].includes(value)
            },
            default: "large"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: [Number, String, undefined],
            default: undefined
        },
        height: {
            type: [Number, String, undefined],
            default: undefined
        },
        rounded: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        large() {
            return this.size === "large"
        },
        small() {
            return this.size === "small"
        },
        xLarge() {
            return this.size === "x-large"
        },
        xSmall() {
            return this.size === "x-small"
        }
    }
};
</script>

<style scoped>

</style>